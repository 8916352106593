import React, {useEffect} from 'react';
import Typography from '@material-ui/core/Typography';
import Fade from '@material-ui/core/Fade';
import { Layout, SEO } from 'components';
import { Animated } from 'components';
import { useCartContext } from '../context/cart'


const SuccessPage = () => {
  const [, dispatch] = useCartContext()

  useEffect(() => {
    dispatch({ type: 'EMPTY_CART', payload: {}})
  }, [dispatch])

  return <Layout>
    <SEO title='Success' />
    <Fade in timeout={1000}>
        <Typography variant='h1' color='textPrimary'>
          <Animated>Success!</Animated>
        </Typography>
      </Fade>
      <Typography variant='h6' color='textPrimary'>
        Your order is on its way!
    </Typography>
  </Layout>
};

export default SuccessPage;
